import React, { Component } from "react";
// import { Link } from "gatsby";
//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/css/bootstrap.css";
// // import { Modal, Button } from "antd";
//import Layout from "../components/header";
import axios from "axios";
import { graphql } from 'gatsby';
//  import {navigate } from 'gatsby';
import HeaderLight from "./../components/headerLight";
import SweetAlert from "sweetalert-react";
//  import Footer from "./../components/footer";
// import { element } from "prop-types";
import moment from "moment";
import { Helmet } from 'react-helmet';
import { Modal, Button, Spin, Rate, Form, Input } from 'antd';
import { Table } from 'antd';
// import SweetAlert from 'react-bootstrap-sweetalert';
require("../css/orderdetails.css");

var FilterableTable;

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      data: [],
      fields: [],
      filterable: false,
      viewdetail: [],
      showmodal: false,
      visible: false,
      columns: [],
      name: "",
      email: "",
      phone: "",
      spin: false,
      Cancelbtn: "block",
      Canceled: "none",
      cancelorder: false,
      prodId: "",
      prodTitle: "",
      refresh: true,
      viewdetailspin: false,
      price: "",
      quantity: "",
      Mvisible: false,
      loading: false,
      productComments: "",
      productRating: "",
      Mloading: false


    }
    this.viewdetails = this.viewdetails.bind(this);
    this.showcancelorder = this.showcancelorder.bind(this);
    this.showrateorder = this.showrateorder.bind(this);
    this.canacelbtuttonview = this.canacelbtuttonview.bind(this);
    this.reviewModal = this.reviewModal.bind(this);

  }

  // showModal = () => {
  //   this.setState({
  //     visible: true,
  //   });
  // };

  handleOk = e => {
    // console.log(e);
    this.setState({
      visible: false,

    });
    //  var testdata= document.getElementsByClassName(this.state.prodTitle)
    //  testdata[0].style.display="none"
  };

  handleMOk = e => {
    // console.log(e);
    let ratingCheck = this.state.productRating;
    // let reviewCheck = this.state.productComments;

    if (ratingCheck == "") {
      // console.log("opppurnity to improve null so add is required");
      // this.setState({
      //   requiredOpportunityWarning: "This field is required**"
      // });
      alert("Please give your Rating for the product to proceed")
    } else {


      this.setState({
        Mloading: true
      })
      var rateproduct = {
        id: this.state.RatingProductId,
        title: this.state.RatingProductTitle,
        rating: this.state.productRating,
        review: this.state.productComments,
        username: this.state.name,
        rateStatus: "yes",
        ratingName: "rating",
      }
      // console.log(rateproduct);

      //api call


      axios.post("https://dashboard.nativecircle.in/wp-json/api/rate_order", rateproduct)

        .then(response => {
          // console.log(response,"hello")
          // {
          //   this.state.refresh === true ? this.setState({ refresh: false }) : this.setState({ refresh: true })
          // }
          if (response.data.status == 200) {
            // this.setState({Cancelbtn:"none",Canceled:"block"});
            // console.log(response.data);
            // this.setState({

            //new code
            //   RatingProductId: "",
            //   productRating: "",
            //   productComments: "",
            //   Mloading: false
            // }, () => {
            //   this.setState({
            //     Mvisible: false,
            //     visible: false
            //   })

            // });
            //new code ends



            // document.getElementById(this.state.prodTitle).style.display = "none";
            // document.getElementsByClassName(this.state.product_title).style.display="block"
            // console.log(data,"tessssst");
            // var data = document.getElementsByClassName(this.state.prodTitle)
            // data[0].style.display = "block"
            //console.log(data[0],'testststststststst')
          }
          else if (response.data.status == 404) {
            alert("failed")
          }

          // for closing
          this.setState({
            RatingProductId: "",
            productRating: "",
            productComments: "",
            Mloading: false
          }, () => {
            this.setState({
              Mvisible: false,
              visible: false
            })

          });
          //for closing ends here



        })
    }



    //  var testdata= document.getElementsByClassName(this.state.prodTitle)
    //  testdata[0].style.display="none"
  };
  handleMCancel = e => {
    // console.log(e.target.value);
    // console.log(e, "comments changed");
    this.setState({
      Mvisible: false
    });
    //  var testdata= document.getElementsByClassName(this.state.prodTitle)
    //  testdata[0].style.display="none"
  };


  handleComments = e => {
    // console.log(e.target.value);
    // console.log(e, "comments changed");
    this.setState({
      productComments: e.target.value
    });
    //  var testdata= document.getElementsByClassName(this.state.prodTitle)
    //  testdata[0].style.display="none"
  };

  handleRating = e => {
    // console.log(e);
    // console.log(e, "comments changed");


    this.setState({
      productRating: e,
    }, () => {
      // console.log(this.state.productRating);

    });

    //  var testdata= document.getElementsByClassName(this.state.prodTitle)
    //  testdata[0].style.display="none"
  };

  // handleCancel = e => {
  //   console.log(e);
  //   this.setState({
  //     visible: false,
  //   });
  // };




  reviewModal(id, product_title) {
    this.setState({
      Mvisible: true,
      RatingProductId: id,
      RatingProductTitle: product_title,
    });
  }

  //new code
  showrateorder(id, product_title) {
    // alert(product_title);

    var rateproduct = {
      id: id,
      title: product_title,
      rating: 6,
      review: "grt product",
      rateStatus: "yes",
      postId: 800,
      ratingName: "rating"
    }
    // console.log(rateproduct, "ratingg");


    axios.post("https://dashboard.nativecircle.in/wp-json/api/rate_order", rateproduct)

      .then(response => {
        // console.log(response,"hello")
        // {
        //   this.state.refresh === true ? this.setState({ refresh: false }) : this.setState({ refresh: true })
        // }
        if (response.data.status == 200) {
          // this.setState({Cancelbtn:"none",Canceled:"block"});
          // console.log(response.data);
          // document.getElementById(this.state.prodTitle).style.display = "none";
          // document.getElementsByClassName(this.state.product_title).style.display="block"
          // console.log(data,"tessssst");
          // var data = document.getElementsByClassName(this.state.prodTitle)
          // data[0].style.display = "block"
          //console.log(data[0],'testststststststst')
        }
        else if (response.data.status == 404) {
          alert("failed")
        }

      })
  }



  showcancelorder(id, product_title, price, quantity) {
    //console.log(product_title,"hgtcfgbhjnmkjnbygvt")
    this.setState({
      cancelorder: true,
      prodId: id,
      prodTitle: product_title,
      price: price,
      quantity: quantity
    })
    //console.log(this.state.prodId,"vijay")
  }
  canacelbtuttonview(product_title) {
    // console.log(product_title,"anillllll")
    var data = document.getElementsByClassName(product_title)
    data[0].style.display = "none"
  }



  cancelpro() {
    // console.log(id,product_title,"helllo")
    //console.log(this.state.prodTitle,"soujanaya")
    var cancelproduct = {
      id: this.state.prodId,
      product_title: this.state.prodTitle,
      price: this.state.price,
      quantity: this.state.quantity,
      status: "cancelled",
      email: window.sessionStorage.getItem("email")
    }

    axios.post("https://dashboard.nativecircle.in/wp-json/api/cancel_order", cancelproduct)

      .then(response => {
        // console.log(response,"hello")
        {
          this.state.refresh === true ? this.setState({ refresh: false }) : this.setState({ refresh: true })
        }
        if (response.data.status == 200) {
          // this.setState({Cancelbtn:"none",Canceled:"block"});

          document.getElementById(this.state.prodTitle).style.display = "none";
          // document.getElementsByClassName(this.state.product_title).style.display="block"
          // console.log(data,"tessssst");
          var data = document.getElementsByClassName(this.state.prodTitle)
          data[0].style.display = "block"
          //console.log(data[0],'testststststststst')
        }

      })
  }

  //view details code//
  viewdetails(id) {
    //document.getElementById(id).style.display="none";
    if (this.state.prodTitle != "") {
      var testing = document.getElementsByClassName(this.state.prodTitle);
      testing[0].style.display = "none"
    }
    this.setState({
      visible: true,
      viewdetailspin: true
    });
    var details = {
      productid: id
    }
    axios.post("https://dashboard.nativecircle.in/wp-json/api/viewdetails", details)
      .then(response => {
        this.setState({ viewdetailspin: false })
        // console.log(response.data.message, "check")
        var columns = [{ title: 'Product    Name', dataIndex: 'product_title' },
        { title: 'Quantity', dataIndex: 'quantity' },
        { title: 'Price', dataIndex: 'price' },
        { title: 'Ordered Date', dataIndex: 'createdAt', render: (record) => { return moment(record.createdAt).format('DD-MM-YYYY') } },
        // { title: 'Gst', dataIndex: 'gst' },
        { title: 'Delivery    Charge', dataIndex: 'deliveryCharge' },
        // { title: 'Rating', dataIndex: 'rating' },
        {
          title: "Rate",
          key: "rate",
          render: (record) => (

            <div>


              {/* {this.canacelbtuttonview(record.product_title)} */}
              {record.rated == "yes" && record.order_status == "ordered" ? (<p className="cancelbtnpd" >rated</p>) : (

                null
              )}



              {record.rated == "no" && record.order_status == "ordered" ? (<button class="order_details_cancelbtn"
                //  id={record.product_title}

                onClick={() => this.reviewModal(id, record.product_title)}>Rate</button>) : (

                  null
                )}


              {/* <p className={record.product_title} style={{ display: "none" }}>Cancelled</p> */}
            </div>


          ),
        },
        {
          title: "Action",
          key: "action",
          render: (record) => (

            <div>
              {/* {this.canacelbtuttonview(record.product_title)} */}
              {record.order_status == "ordered" ? (<button class="order_details_cancelbtn" id={record.product_title}

                onClick={() => this.showcancelorder(id, record.product_title, record.price, record.quantity)}>Cancel</button>) : (
                  <p className="cancelbtnpd" >Cancelled</p>)}


              <p className={record.product_title} style={{ display: "none" }}>Cancelled</p>
            </div>


          ),
        },
        ]

        this.setState({
          viewdetail: response.data.message,
          showmodal: true,
          columns: columns,


        })
      })
      .catch(err => {
        console.log(err);
      });
  }


  //changed code //
  componentDidMount() {
    this.setState({
      spin: true
    })
    // () => {
    //   setTimeout(() => {
    //     this.setState({ spin: false });
    //   }, 1000);
    // }
    // )


    FilterableTable = require('react-filterable-table')
    this.setState({ filterable: true })
    var postdata = {
      email: window.sessionStorage.getItem("email")
    }
    var fields = [{ name: 'paymentId', displayName: "Payment Id", inputFilterable: true, sortable: true },

    { name: 'deliveryType', displayName: "Delivery Type", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'createdAt', displayName: "Ordered Date", inputFilterable: true, exactFilterable: true, sortable: true, render: (props) => { return moment(props.record.createdAt).format('DD-MM-YYYY') } },
    //{ name: 'createdAt', displayName: "Ordered Date", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'totalprice', displayName: "Total Price", inputFilterable: true, exactFilterable: true, sortable: true },
    // { name: 'totalprice', displayName: "Total Price", inputFilterable: true, exactFilterable: true, sortable: true },

    //  {name: 'gst', displayName:"Gst", inputFilterable: true, exactFilterable: true, sortable: true},
    //  {name:'deliveryCharge',displayName:"deliveryCharge", inputFilterable:true, exactFilterable:true, sortable: true },
    {
      name: 'action', displayName: "Action", inputFilterable: true, render: (props) => {
        var paymentId = props.record.paymentId;
        return (<button onClick={() => this.viewdetails(paymentId)} class="viewdetailsbtn"> Rate & View Details</button>)
      }
    }
      // {name:'action', displayName:'Action', render:renderButton}
    ];

    //  function renderButton(props){
    //   console.log(props.record.paymentId)

    //   // return (
    //   //   <button onClick={()=>this.viewdetails(this.id)} class="btn btn-success" id={props.record.paymentId}>View Details</button>
    //   // )
    // }


    axios.post("https://dashboard.nativecircle.in/wp-json/api/order-details", postdata)
      .then(response => {
        //console.log(response.data.message)
        this.setState({
          data: response.data.message,
          fields: fields,
          // name:response.data.message[0].fullName,
          // email:response.data.message[0].email,
          // phone:response.data.message[0].phone


        }, () => {
          this.setState({ spin: false });
        })

      })
      .catch(err => {
        console.log(err);
      });


    axios.post("https://dashboard.nativecircle.in/wp-json/api/login", postdata)
      .then(response => {
        this.setState({
          name: response.data.message[0].full_name,
          email: response.data.message[0].email,
          phone: response.data.message[0].phone
        })

      })
      .catch(err => {
        console.log(err);
      });
  }




  render() {
    const { Mvisible, Mloading } = this.state;
    return (


      <div >

        <Modal
          visible={Mvisible}
          title="Give your ratings & comments on this product "
          onOk={this.handleMOk}
          onCancel={this.handleMCancel}
          footer={[
            <Button key="back" onClick={this.handleMCancel}>
              I will do it later
            </Button>,
            <Button key="submit" type="secondary" loading={Mloading} onClick={this.handleMOk}>
              Submit
            </Button>,
          ]}
        >

          <Form.Item name="rate" label="Rate">
            <Rate onChange={this.handleRating} value={this.state.productRating} />
          </Form.Item>
          {/* <TextArea rows={4} /> */}
          <Form.Item
            label="Comments"
            name="comments"

            rules={[
              {
                required: true,
                message: 'Please input your comment!',
              },
            ]}
          >
            <Input onChange={this.handleComments} value={this.state.productComments} />
          </Form.Item>
        </Modal>


        <SweetAlert

          show={this.state.cancelorder}
          title="Are you sure you want to cancel ?"
          showCancelButton
          cancelButtonText="No"
          confirmButtonText="Yes"
          customClass="sweetalertwidth"

          onConfirm={() => {
            this.setState({ cancelorder: false })
            this.cancelpro()
          }}
          onCancel={() => { this.setState({ cancelorder: false }) }}
        />


        <Spin tip="Loading..."
          spinning={this.state.spin}
          wrapperClassName="spinClass">
          <Helmet>
            {/* <link rel="stylesheet" href="../dist/style.css" /> */}
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />


          </Helmet>

          <HeaderLight pathname="/order_details" />
          {/* <div class="col-sm-12 col-xs-12 col-md-12 orderdetailsfnemph">

                      <div class="col-md-4 orderdetails_fullName">
                        Name:{this.state.name}
                      </div> 

                      <div class="col-md-4 orderdetails_fullName ">
                        Email:{this.state.email}
                      </div>

                      <div class="col-md-4 orderdetails_fullName ">
                        Phone:{this.state.phone}
                      </div>

            </div> */}

          <div className="orderdetaisl_maindiv" id="orderdetails_pagination">
            {this.state.filterable ? (
              <FilterableTable
                namespace="People"
                initialSort="name"
                tableClassName="tablecustomize"
                pageSize={5}
                pageSizes={[10, 20, 30, 50]}
                data={this.state.data}
                fields={this.state.fields}
                pagerTopClassName="order_details_pagination"
                pagerBottomClassName="orderdetailsbottompage"
                noRecordsMessage="There are no products to display"
                noFilteredRecordsMessage="No Product match your filters!"


              />
            ) : "Loding..."}


          </div>
          {/* modal code */}
          <div>
            {/* <Button type="primary" onClick={this.viewdetails('pay_DLpTjUpoFNQ8TM')}>
          Open Modal
        </Button> */}
            {/* <div id="orderDetailsModal"> */}
            <Modal

              className="orderDetailsModal"
              title="Product details"
              visible={this.state.visible}
              onOk={this.handleOk}

              // onCancel={this.handleCancel}
              width={800}
              closable={false}
              cancelButtonProps={{ disabled: true }}

              footer={[
                <Button key="submit" type="primary" onClick={this.handleOk}>
                  Done
            </Button>
              ]}




            >
              <Spin tip="Loading..."
                spinning={this.state.viewdetailspin}
                wrapperClassName="spinClass">

                <Table class="orderlistinmodal" pagination={{ pageSize: 5 }} columns={this.state.columns} dataSource={this.state.viewdetail} />
              </Spin>
            </Modal>

          </div>
          {/* </div> */}
        </Spin>
      </div>



    )


  }

}




export default OrderDetails;


export const pageQuery = graphql`
query  {
          orders: allMysqlOrders{
          edges{
          node{
          paymentId
          email
      }
    }
  }
    products: allMysqlProducts{
          edges{
          node{
          paymentId
            product_title
        quantity
        price
        createdAt(formatString: "DD-MM-YYYY")
      }
    }
  }
}
 
`;



